/**
 * Resolves the frontend URL from the given URL.
 * If the URL starts with 'product/', it replaces it with '/'.
 * (Storyblok has `product` folder for products, therefore 'product/' is a prefix for product URLs.)
 * If the URL ends with '/', it removes the trailing slash.
 * @param url - The URL to resolve.
 * @returns The resolved frontend URL.
 */
const PRODUCT_URL_PREFIX = 'product/';

export function resolveUrl(url: string) {
  let frontendUrl = url;

  if (url.startsWith(PRODUCT_URL_PREFIX)) {
    frontendUrl = frontendUrl.replace(PRODUCT_URL_PREFIX, '');
  }

  if (frontendUrl.endsWith('/')) {
    frontendUrl = frontendUrl.slice(0, -1);
  }

  return frontendUrl;
}
