export const SORTED_FILTER_ATTRIBUTE_CODES = [
  'grupa_upraw',
  'active_substance',
  'sklad',
  'agrofag',
  'manufacturer',
  'hodowla',
  'zaprawa',
  'ilosc_nasion',
  'purpose',
  'price',
  'volume_decimal',
  'sklad_nawozu',
  'wysokosc',
  'depth',
  'size',
  'diameter',
  'length',
  'width',
  'przylacze',
  'przylacze_gorne',
  'przylacze_dolne',
  'srednica_cal',
  'color',
  'screw',
  'spacing',
  'wydajnosc',
  'dysza_dolewajaca',
  'dysza_glowna',
  'ilosc_warstw',
  'temperatura_robocza',
  'moc_silnika',
  'cisnienie',
  'lanca',
  'rodzaj_zebow_pily',
  'dlugosc_ostrza',
  'srednica_ciecia',
  'pack',
  'rodzaj_sekatora',
  'dla_leworecznych',
  'obrotowa_rekojesc',
  'typ_glowicy_sekatora',
  'doradca',
  'bestseller',
  'new',
];
