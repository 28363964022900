/* eslint-disable  */

export default {
    "Account": "Konto",
    "Wishlist": "Ulubione",
    "Cart": "Koszyk",
    "Home": "Start",
    "Menu": "Menu",
    "Filters": "Filters",
    "Sort: Default": "Domyślnie",
    "Sort: Name A-Z": "Nazwa A-Z",
    "Sort: Name Z-A": "Nazwa Z-A",
    "Sort: Price from high to low": "Cena od najwyższej do najniższej",
    "Sort: Price from low to high": "Cena od najniższej do najwyższej",
    "Add new address": "Dodaj nowy adres",
    "Back to home": "Wrócić do strony głównej",
    "Billing address": "Dane do faktury",
    "Create an account": "Utwórz konto",
    "Discount": "Rabat",
    "Email address": "Adres e-mail",
    "Enter promo code": "Wpisz kod promocyjny",
    "First Name": "Imię",
    "Forgot Password": "Jeśli nie pamiętasz hasła, możesz je zresetować.",
    "Forgot Password Modal Email": "Adres e-mail, którego używasz do logowania:",
    "Forgotten password?": "Zapomniane hasło?",
    "I want to create an account": "Chcę założyć konto",
    "Last Name": "Nazwisko",
    "Login": "Zaloguj sie",
    "login in to your account": "zaloguj się na swoje konto",
    "Match it with": "Dopasuj to do",
    "No account": "Nie masz jeszcze konta?",
    "or": "lub",
    "Order summary": "Podsumowanie zamowienia",
    "Other products you might like": "Inne produkty, które mogą Ci się spodobać",
    "Password": "Hasło",
    "Password Changed": "Hasło zostało pomyślnie zmienione. Możesz teraz wrócić do strony głównej i zalogować się.",
    "Products": "Produkty",
    "Quantity": "Ilość",
    "Register": "Zarejestruj się",
    "Register today": "Zarejestruj się już dziś",
    "Repeat Password": "Powtórz hasło",
    "Reset Password": "Zresetuj hasło",
    "Save Password": "Zapisz hasło",
    "Shipping": "Wysyłka",
    "Shipping address": "Adres dostawy",
    "Shipping method": "Metoda wysyłki",
    "Sign Up for Newsletter": "Zarejestruj się aby otrzymać newsletter",
    "Subtotal": "Suma częściowa",
    "Thank You Inbox": "Jeśli wiadomość nie dotrze do Twojej skrzynki odbiorczej, spróbuj użyć innego adresu e-mail, którego mogłeś użyć do rejestracji.",
    "Total": "Suma",
    "Vue Storefront Next": "Vue Storefront Next",
    "Your email": "Twój email",
    "My wishlist": "Moja lista życzeń",
    "You are not authorized, please log in.": "Zostałeś wylogowany. Zaloguj się ponownie.",
    "House/Apartment number": "Numer budynku/mieszkania",
    "Apartment number": "Numer mieszkania",
    "Building number": "Numer budynku",
    "Street Name": "Nazwa ulicy",
    "City": "Miasto",
    "State/Province": "Województwo",
    "Zip-code": "Kod pocztowy",
    "Country": "Kraj",
    "Phone number": "Numer telefonu",
    "This field is required": "To pole jest wymagane",
    "The field should have at least 2 characters": "Pole powinno zawierać co najmniej 2 znaki",
    "The field should have at least 4 characters": "Pole powinno zawierać co najmniej 4 znaki",
    "The field should have at least 8 characters": "Pole powinno zawierać minimum 8 znaków",
    "There are no shipping methods available for this country. We are sorry, please try with different country.": "Dla tego kraju nie są dostępne żadne metody wysyłki. Przepraszamy, spróbuj wybrać inny kraj.",
    "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "Wystąpił błąd podczas próby wybrania tej metody wysyłki. Przepraszamy, spróbuj użyć innej metody wysyłki.",
    "We can't find products matching the selection.":"Przepraszamy, nie znaleźliśmy żadnych pasujących produktów.",
    'An error occurred': 'Wystąpił błąd',
    "Yes": "Tak",
    "No": "Nie",
    "From": "Z",
    "To": "Do",
    "Enter different address":"Wprowadź inny adres",
    "Add to cart":"Dodaj do koszyka",
    "Starting at": "Zaczynając od",
    "Company name": "Nazwa firmy",
    "Tax identification number": "NIP",
    "Price": "Cena",
    "Please provide a valid NIP": "Proszę podać prawidłowy NIP",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.":"Wprowadziłeś błędne dane logowania. Spróbuj ponownie.",
    "Error during reCaptcha verification. Please try again.": "Błąd podczas weryfikacji reCaptcha. Spróbuj ponownie.",
    "A customer with the same email address already exists in an associated website.": "Klient o tym samym adresie e-mail już istnieje w powiązanej witrynie.",
    "The requested qty is not available": "Wybrana ilość nie jest dostępna",
    // used in order history statuses
    "processing": "W trakcie realizacji",
    "pending_payment": "Oczekuje na płatność",
    "holded": "Oczekuje na przelew",
    "open": "Open",
    "pending": "Oczekujące",
    "confirmed": "Potwierdzone",
    "shipped": "Zamówienie wysłane",
    "complete": "Zamówienie zrealizowane",
    "canceled": "Zamówienie anulowane",
    "closed": "Zamówienie dostarczone",

    // coupon error messages
    "A coupon is already applied to the cart. Please remove it to apply another": "Kupon jest już zastosowany. Usuń go, aby zastosować inny",
    "The coupon code isn't valid. Verify the code and try again.":"Kod kuponu jest nieprawidłowy. Sprawdź kod i spróbuj ponownie."
  };
